@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif !important;
}

a {
  color: rgb(255, 255, 255);
}

p {
  margin: 0px !important;
  font-size: 14px;
}

input {
  font-size: 16px !important;
}

.bg-primary {
  background-color: #6724fd !important;
}

.my-input {
  border-color: #fff;
  outline: 0px;
  border: 0px;
  border-radius: 5px;
  padding: 8px;
  color: black;
}

.password-eye {
  position: absolute;
  color: black;
  right: 10px;
  bottom: 5px;
}

/* disable desktop view  */
.switch-to-mobile-wrapper {
  display: initial;
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
}

.global-wrapper {
  display: none;
}

@media only screen and (max-width: 540px) {
  .global-wrapper {
    display: initial;
  }
  .switch-to-mobile-wrapper {
    display: none;
  }
}
